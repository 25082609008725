<template>
  <div>
    <div
      class="event-board"
      style="text-align: center;"
    >
      <div class="doctorVoice-detail-202406">
        <div class="status-wrap">
          <!-- 나의 적립현황 -->
          <div class="number-acquired number-box">
            {{ isLogOutMember ? 0 : takeKeyCount }}
          </div>
          <!-- 오픈 가능한 선물박스 -->
          <div class="number-openAble number-box">
            {{ isLogOutMember ? 0 : (parseInt(takeKeyCount / 3) - openBoxCount) }}
          </div>
          <!-- 오픈 완료한 선물박스 -->
          <div class="number-open number-box">
            {{ isLogOutMember ? 0 : openBoxCount }}
          </div>
        </div>

        <!-- 오픈완료, 오픈하기, 오픈하기 비활성화 -->
        <div class="btn-open-event-06">
          <a title="선물 박스">
            <img
              :src="getImagePath(openBtnState)"
              :style="getImageStyle(openBtnState)"
              @click="getImageClick(openBtnState)"
            >
          </a>
        </div>
        <div class="btn-go-banner">
          <a
            title="메디컬 트렌드 보고 스티커 적립하기"
            @click="$router.push({ name: 'app-medical-trend-main', query: { entry: 122 } })"
          >
            <img
              src="@/assets/images/events/event240430/detail-06.png"
              alt="메디컬 트렌드 보고 스티커 적립하기"
            >
          </a>
        </div>
        <!-- 배경 -->
        <img
          src="@/assets/images/events/event240430/detail-bg-2406.png"
          alt="선생님의 의견이 소중한 정보가 되는 닥터보이스"
        >
      </div>
    </div>

    <component
      :is="popupComponent"
      ref="preProcessPopup"
      :event-id="eventId"
      @close="closePreProcessPopup"
    />
    <alert-dialog :options="alertProps" />
    <confirm-dialog :options="confirmProps" />
  </div>
</template>
<script>
import axios from '@axios'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import { isUserLoggedIn } from '@/auth/utils'
import { setStatistics } from '@/common/statistics/service'
import { getUserLoginInfo } from '@/common/userInfo/service'

require('@/assets/scss/event.scss')

export default {
  props: {
    eventId: {
      type: Number,
      required: true,
    },
    contentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      today: dayjs().format('YYYY-MM-DD'),
      takeKeyCount: 0,
      openBoxCount: 0,
      isProcessing: false,
      isLogOutMember: false,
      startDate: '2024-06-04',
      endDate: '2024-06-30',
      userPhoneNumber: '',
      openBtnState: 'disabled',
    }
  },
  computed: {
    ...mapGetters({
      join: 'util/getJoin', // 회원여부
      userName: 'account/getName', // 회원 로그인 여부
      ysrUserName: 'infoData/getUserName',
      shaEnLicenseNum: 'infoData/getShaEnLicenseNum',
      hAddress: 'infoData/getHAddress',
      medicalDept: 'infoData/getMedicalDept',
    }),
    popupComponent() {
      return () => import('@/components/popups/EventRewardAgreeForm.vue')
    },
  },
  watch: {
    shaEnLicenseNum() {
      if (this.shaEnLicenseNum) {
        this.fetchEventHistory()
      }
      if (this.join === null) getUserLoginInfo()
    },
    userName() {
      if (this.userName) {
        this.fetchEventHistory()
      } else {
        this.isLogOutMember = true
      }
    },
  },
  async created() {
    // 회원여부 조회
    if (this.join === null) getUserLoginInfo()
    const isLoggedIn = await isUserLoggedIn()

    this.isLogOutMember = (this.join && !isLoggedIn)

    await this.fetchEventHistory()
  },
  methods: {
    fetchState() {
      let boxState = 'disabled'

      if (this.isLogOutMember) {
        boxState = 'disabled'
      } else if (parseInt(this.openBoxCount, 10) === 3) {
        boxState = 'completion'
      } else if (this.takeKeyCount < 3 || parseInt(this.takeKeyCount / 3, 10) <= this.openBoxCount || this.checkDisableDate(this.startDate, this.endDate)) {
        boxState = 'disabled'
      } else {
        boxState = 'open'
      }

      this.openBtnState = boxState
      return boxState
    },
    getImagePath(state) {
      // completion = 오픈 완료
      // disabled = 오픈 비활성화
      // open = 오픈 하기

      // eslint-disable-next-line import/no-dynamic-require, global-require
      return require(`@/assets/images/events/event240430/btn-detail-${state}.png`)
    },
    getImageStyle(state) {
      // completion = 오픈 완료
      // disabled = 오픈 비활성화
      // open = 오픈 하기

      if (state === 'open') {
        if (!this.isProcessing) {
          return { cursor: 'pointer' }
        }
      }

      return { cursor: 'default' }
    },
    getImageClick(state) {
      // completion = 오픈 완료
      // disabled = 오픈 비활성화
      // open = 오픈 하기

      if (state === 'open') {
        return this.openLuckyBox()
      }

      return null
    },
    checkDisableDate(stDate, edDate) {
      return (this.today < stDate || edDate < this.today)
    },
    async getEventHistoryWithId(isReward) {
      const result = await axios.get(`/fu/event/hiscount/${this.eventId}`, {
        params: {
          userHash: this.shaEnLicenseNum,
          isRewardFin: isReward
        },
      })
        .then(rs => rs.data)
        .catch((err) => {
          this.showAlertDialog(`이벤트 ${isReward ? '보상' : '참여'} 여부 체크 도중 오류가 발생하였습니다.\n잠시 후에 다시 시도하여 주시기 바랍니다.\n${err}`)
          return null
        })

      return result
    },
    async fetchEventHistory() {
      if (!this.shaEnLicenseNum) {
        return
      }
      this.takeKeyCount = await this.getEventHistoryWithId(false)
      this.openBoxCount = await this.getEventHistoryWithId(true)

      this.fetchState()
    },
    async openLuckyBox() {
      if (this.isProcessing) return
      this.isProcessing = true

      if (this.join) {
        const isLogged = await isUserLoggedIn()
        /// / 회원
        if (isLogged) {
          /// / 로그인 상태
          this.checkEventInfo()
        } else {
          /// / 로그아웃 상태
          this.showConfirmDialog('이벤트 참여 및 내역 조회를 위해서는 로그인이 필요합니다.\n지금 로그인하시겠습니까?', (result) => {
            if (result) {
              this.isProcessing = false
              this.redirectLogin()
            }
          })
        }
      } else {
        /// / 비회원
        this.checkEventInfo()
      }
    },
    async checkEventInfo() {
      // 오픈 가능 여부 한 번 더 확인
      await this.fetchEventHistory()

      if (parseInt(this.takeKeyCount / 3, 10) > this.openBoxCount) {
        if (parseInt(this.openBoxCount, 10) === 0) {
          // 동의 내역 X : 개인정보 수집 이용 안내 팝업 (confirm)
          this.$refs.preProcessPopup.call()
        } else {
          // 동의 내역 O : 경품 전달 안내 팝업 출력
          this.userPhoneNumber = await this.getUserPhoneNumber()
          this.createHistoryOpenBox()
        }
      } else {
        this.showAlertDialog('오픈할 수 있는 선물박스가 존재하지 않습니다.')
        this.isProcessing = false
      }
    },
    async getUserPhoneNumber() {
      const phoneNumber = await axios.get(`/fu/event/privacy/${this.eventId}`, {
        params: {
          userHash: this.shaEnLicenseNum,
        },
      })
        .then(rs => rs.data.userPhoneNumber).catch(() => {
          this.isProcessing = false
        })

      return phoneNumber
    },
    async createHistoryOpenBox() {
      const isLogged = await isUserLoggedIn()

      axios.post(`/fu/event/history-reward`, {
        EventId: this.eventId,
        ContentId: this.contentId,
        UserPhoneNumber: this.userPhoneNumber,
        UserHash: this.shaEnLicenseNum,
        UserName: (isLogged) ? this.userName : this.ysrUserName,
        MedicalDept: this.medicalDept,
        UserWorkAddress: this.hAddress
      })
        .then(async rs => {
          if (parseInt(rs.data.rewardType, 10) === 2) {
            setStatistics(121, this.eventId)
            this.showAlertDialog(`${rs.data.giftGoodsName}에 당첨되셨습니다.\n이벤트 참여 시 입력하신 휴대폰번호로 기프티콘이 전송되었습니다.`)
          } else {
            this.showAlertDialog('이벤트 보상 설정에 오류가 있습니다.\n관리자에게 문의해 주세요.')
          }

          this.isProcessing = false
          await this.fetchEventHistory()
        }).catch(error => {
          if (error.response && error.response.data) {
            this.showAlertDialog(error.response.data)
          } else {
            this.showAlertDialog('오류가 발생했습니다.\n관리자에게 문의해주세요.')
          }
          this.isProcessing = false
        })
    },
    redirectLogin() {
      this.$router.push({ name: 'login-main', query: { redirect: 'app-event-main' }, params: { id: this.contentId } }).catch(() => {})
    },
    closePreProcessPopup(isConfirm) {
      if (isConfirm) {
        // 박스 오픈
        this.createHistoryOpenBox()
      }
    },
  }
}
</script>

<style scoped>
  body {
            margin: 0;
            padding: 0;
        }
        img {
            display: block;
            margin: 0 auto;
        }
        area:hover {
            cursor: pointer;
        }
        .event-board {
            width: 1000px;
            margin: 0 auto;
            position: relative;
        }
        .event-checked img {
            display: none;
            position: absolute;
        }
</style>
